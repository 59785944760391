import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import 'hammerjs';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

// eslint-disable-next-line @typescript-eslint/no-require-imports
const version = require('../package.json').version;

Sentry.init({
  dsn: environment.SentryDsn,
  environment: environment.name,
  release: version,
  allowUrls: environment.SentryAllowedUrls,
  integrations: [
    Sentry.browserTracingIntegration({
      //tracingOrigins: environment.SentryAllowedUrls,
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.1,
});

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error('Global error:', err));
