import { Injectable } from '@angular/core';
import moment from 'moment';

@Injectable()
export class DateFunction {
  countTotalDays = (startDate, endDate): number => {
    const start = moment.unix(startDate);
    const end = moment.unix(endDate);
    return moment.duration(end.diff(start)).asDays();
  };
}
