import { NgForOf } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { take } from 'rxjs/operators';

import { ITradingPlan } from '@c/trading-hub/trading-plan/trading-plan.model';
import { SanitizeHtmlPipe } from '@core/pipes/sanitize-html.pipe';
import { ObservableService } from '@s/observable.service';

@Component({
  selector: 'app-print-trading-plan',
  templateUrl: './print-trading-plan.component.html',
  styleUrls: ['./print-trading-plan.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgForOf, SanitizeHtmlPipe],
})
export class PrintTradingPlanComponent implements OnInit {
  protected planToPrint: ITradingPlan | null = null;

  constructor(
    private observableService: ObservableService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.observableService.tradingHubPlanToPrint.pipe(take(1)).subscribe((planToPrint) => {
      this.planToPrint = planToPrint;
      this.changeDetectorRef.markForCheck();
    });
  }
}
