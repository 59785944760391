import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { PrintDataComponent } from '@c/print-data/print-data.component';
import { PrintTradingPlanComponent } from '@c/trading-hub/print-trading-plan/print-trading-plan.component';
import { MainV2Module } from '@m/main-v2.module';
import { SharedModule } from '@sh/shared.module';

@NgModule({
  declarations: [PrintDataComponent],
  exports: [PrintDataComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    SharedModule,
    MainV2Module,
    PrintTradingPlanComponent,
  ],
})
export class PrintDataModule {}
