<div class="data-for-print trading-plan">
  <h1 class="doc-title">Trading Plan | {{ planToPrint?.title }}</h1>

  <div
    *ngFor="let section of planToPrint?.sections"
    class="section"
  >
    <h2 class="section-title">{{ section.title }}</h2>
    <div
      [innerHTML]="section.content | sanitizeHtml"
      class="section-content"
    ></div>
  </div>
</div>
